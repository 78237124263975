/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import Resource from "../../components/Utils/Resource";
import { Container, Row, Col } from "reactstrap";

function Promotions() {
  return (
    <>
      <div id="promotions" className="section">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h1 className="title">Promotions</h1>
              <p className="p-larger">
                Here are some Hunter Gospel Ministries promotional materials that may be used in your church or organisation.
              </p>
              <Resource title="Renew 2025 slide" url="https://s3.ap-southeast-2.amazonaws.com/talks.hgm.org.au/renew/2025/Renew_2025_Slide.png" fileName="Renew_2025_Slide.png" />

              <Resource title="Renew 2025 A3 Poster" url="https://s3.ap-southeast-2.amazonaws.com/talks.hgm.org.au/renew/2025/Renew_2025_A3_Poster.pdf" fileName="Renew_2025_A3_Poster.pdf" />

              <Resource title="Renew 2025 A4 Poster" url="https://s3.ap-southeast-2.amazonaws.com/talks.hgm.org.au/renew/2025/Renew_2025_A4_Poster.pdf" fileName="Renew_2025_A4_Poster.pdf" />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Promotions;
