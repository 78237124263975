/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { Button, Col, Container, Row } from "reactstrap"; // Button,

function Conferences() {
  return (
    <>
      <Container className="tim-container">
        <div id="conferences" className="section">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center">
                <h1 className="title">Conferences</h1>
              </Col>
            </Row>
            <Row>
              <Col className="ml-auto mr-auto text-center" lg="4" md="8" sm="12">
              <a href = "/renew"> 
                  <img
                    alt="Renew Logo"
                    className="img-rounded img-responsive"
                    src={require("assets/img/renew_conference/logos/renew_logo_only_green.jpg").default}
                  />
                  <h1 className="quicksand renew-green heavy no-margin">Renew</h1>
                  <h3 className="quicksand renew-green light no-margin">Women's Conference</h3>
                </a>
                <p className="pt-5">
                  Renew will be on Saturday the 31st of May 2025, at <a href="https://www.metfordbaptist.org.au/" target="_blank" rel="noreferrer">Metford Community Baptist Church</a>
                </p>
                
                <Button color="warning" size="lg" type="button" href="/renew#currentConference">
                  Registrations now open
                </Button>
              </Col>
              <Col className="mr-auto ml-auto text-center" lg="4" md="8" sm="12">
                <a href = "/recharge"> 
                  <img
                    alt="Recharge Logo"
                    className="img-rounded img-responsive"
                    src={require("assets/img/recharge_conference/logos/recharge_logo_only.jpg").default}
                  />
                  <h1 className="quicksand blue heavy no-margin">Recharge</h1>
                  <h3 className="quicksand blue light no-margin">Men's Conference</h3>
                </a>
                <p className="pt-5">
                  <strong>Recharge</strong> will be on Saturday the 13th of September 2025, at <a href="https://www.google.com/maps/place/Grace+Evangelical+Church+Newcastle+Inc./@-32.9282696,151.6429535,17z/data=!3m1!4b1!4m6!3m5!1s0x6b733c25d420430b:0x2b393a69344dbaf9!8m2!3d-32.9282696!4d151.6429535!16s%2Fg%2F1tcvzlb3?entry=ttu" target="_blank" rel="noreferrer">Grace Evangelical Church</a>.
                </p>
                {/* 
                <Button color="warning" size="lg" type="button" href="/recharge#currentConference">
                  Registrations now open
                </Button> */}
              </Col>
              <Col className="ml-auto mr-auto text-center" lg="4" md="8" sm="12">
                <a href = "/resource"> 
                  <img
                    alt="Resource Logo"
                    className="img-rounded img-responsive"
                    src={require("assets/img/resource_conference/logos/resource_logo_only.jpg").default}
                  />
                  <h1 className="quicksand red heavy no-margin">Resource</h1>
                  <h3 className="quicksand red light no-margin">Bible Teacher's Workshop</h3>
                </a>
                <p className="pt-5">
                  <strong>Resource</strong> will be on for two days on Wednesday the 12th and Thursday the 13th of November in 2025, at <a href="https://www.gecn.org/" target="_blank" rel="noreferrer">Grace Evangelical Church</a>.
                </p>
                {/* <Button color="warning" size="lg" type="button" href="/resource#currentConference">
                  Registrations now open
                </Button> */}
              </Col>
            </Row>
          </Container>
        </div>
      </Container>
    </>
  );
}

export default Conferences;
