import React from "react";
import {
  Card,
  CardBody, CardTitle, Col, Container, Row
} from "reactstrap";


function Committee() {
  return (
    <>
    <div className="section text-center" id="committee">
          <Container>
            <h2 className="title">Committee</h2>
            <Row>
              <Col md="4">
                <Card className="card-profile card-plain">
                  <div className="card-avatar">
                      <img
                        alt="Linda"
                        src={
                          require("assets/img/renew_conference/profiles/Linda_cropped.jpg").default
                        }
                      />
                  </div>
                  <CardBody>
                      <div className="author">
                        <CardTitle tag="h4">Linda</CardTitle>
                        <h6 className="card-category">Charlestown Presbyterian</h6>
                      </div>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4">
                <Card className="card-profile card-plain">
                  <div className="card-avatar">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="Kathryn"
                        src={
                          require("assets/img/renew_conference/profiles/Kathryn_cropped.jpg")
                            .default
                        }
                      />
                    </a>
                  </div>
                  <CardBody>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <div className="author">
                        <CardTitle tag="h4">Kathryn</CardTitle>
                        <h6 className="card-category">Cardiff Heights Baptist Church</h6>
                      </div>
                    </a>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4">
                <Card className="card-profile card-plain">
                  <div className="card-avatar">
                      <img
                        alt="Catherine"
                        src={
                          require("assets/img/renew_conference/profiles/Catherine_cropped.jpg")
                            .default
                        }
                      />
                  </div>
                  <CardBody>
                      <div className="author">
                        <CardTitle tag="h4">Catherine</CardTitle>
                        <h6 className="card-category">AFES Worker, Newcastle University</h6>
                      </div>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4">
                <Card className="card-profile card-plain">
                  <div className="card-avatar">
                      <img
                        alt="Theresa"
                        src={
                          require("assets/img/renew_conference/profiles/Theresa_cropped.jpg").default
                        }
                      />
                  </div>
                  <CardBody>
                      <div className="author">
                        <CardTitle tag="h4">Theresa</CardTitle>
                        <h6 className="card-category">Maitland Evangelical Church</h6>
                      </div>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4">
                <Card className="card-profile card-plain">
                  <div className="card-avatar">
                      <img
                        alt="Susan"
                        src={
                          require("assets/img/renew_conference/profiles/Susan_cropped.jpg").default
                        }
                      />
                  </div>
                  <CardBody>
                      <div className="author">
                        <CardTitle tag="h4">Susan</CardTitle>
                        <h6 className="card-category">Newcastle Central Presbyterian</h6>
                      </div>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4">
                <Card className="card-profile card-plain">
                  <div className="card-avatar">
                      <img
                        alt="Chez"
                        src={
                          require("assets/img/renew_conference/profiles/Chez_cropped.jpg").default
                        }
                      />
                  </div>
                  <CardBody>
                      <div className="author">
                        <CardTitle tag="h4">Chez</CardTitle>
                        <h6 className="card-category">Grace Evangelical Church Newcastle</h6>
                      </div>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4">
                <Card className="card-profile card-plain">
                  <div className="card-avatar">
                      <img
                        alt="Gerlies"
                        src={
                          require("assets/img/renew_conference/profiles/Gerlies_cropped.jpg").default
                        }
                      />
                  </div>
                  <CardBody>
                      <div className="author">
                        <CardTitle tag="h4">Gerlies</CardTitle>
                        <h6 className="card-category">Mayfield Presbyterian Church</h6>
                      </div>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4"> 
                <Card className="card-profile card-plain">
                  <div className="card-avatar">
                      <img
                        alt="Anabelle"
                        src={
                          require("assets/img/renew_conference/profiles/Anabelle_cropped.jpg").default
                        }
                      />
                  </div>
                  <CardBody>
                      <div className="author">
                        <CardTitle tag="h4">Anabelle</CardTitle>
                        <h6 className="card-category">All Saints ANeW</h6>
                      </div>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4">
                <Card className="card-profile card-plain">
                  <div className="card-avatar">
                      <img
                        alt="Megan"
                        src={
                          require("assets/img/renew_conference/profiles/Megan_cropped.jpg")
                            .default
                        }
                      />
                  </div>
                  <CardBody>
                      <div className="author">
                        <CardTitle tag="h4">Megan</CardTitle>
                        <h6 className="card-category">Maitland Evangelical Church</h6>
                      </div>
                  </CardBody>
                </Card>
              </Col>
              <Col md="12">
                <Card className="card-profile card-plain">
                  <div className="card-avatar">
                      <img
                        alt="Georgia"
                        src={
                          require("assets/img/renew_conference/profiles/Georgia_cropped.jpg")
                            .default
                        }
                      />
                  </div>
                  <CardBody>
                      <div className="author">
                        <CardTitle tag="h4">Georgia</CardTitle>
                        <h6 className="card-category">Mayfield Presbyterian Church</h6>
                      </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
    </div>
    </>
)};


export default Committee;