/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { Button, Col, Container, Row } from "reactstrap"; // , Button,  NavLink 


function CurrentConference() {

  // let pageHeader = React.createRef();

  // React.useEffect(() => {
  //   if (window.innerWidth < 991) {
  //     const updateScroll = () => {
  //       let windowScrollTop = window.pageYOffset / 3;
  //       pageHeader.current.style.transform =
  //         "translate3d(0," + windowScrollTop + "px,0)";
  //     };
  //     window.addEventListener("scroll", updateScroll);
  //     return function cleanup() {
  //       window.removeEventListener("scroll", updateScroll);
  //     };
  //   }
  // });

  return (
    <>
      <div id="currentConference">
        {/* <div
          className="page-header"
          data-parallax={false}
          style={{
          backgroundImage:
            "url(" + require("assets/img/renew_conference/heros/2022/Hero_2022.jpg").default + ")",
          }}
          ref={pageHeader}
        > 
        </div>*/}
        <div className="section section-dark">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <img
                  alt="..."
                  className="img-rounded img-responsive"
                  src={require("assets/img/renew_conference/2025/Renew_2025.png").default}
                />

                <br /><br /><br />

                <h1 className="title-brand">Renew - Life in the Spirit</h1>                

                <p className="p-larger description pt-5 pb-2">
                  Living as Christian women in this world, we can sometimes be consumed by feelings of stress, fear, busyness and insecurity. Join us at Renew 2025 as Greta Gaut leads us through 2 Timothy 1 and 2, showing us how we can experience the Spirit of power, not of fear (2 Tim 1:7), by practicing Christian mindfulness in our increasingly frenetic world. Greta will also explore the tension between us being given the Spirit of power and love through grace, and our lived experience of this Spirit expressed through the work of self-discipline. It is sure to be a wonderful opportunity to renew our minds and help us in our Christian walk.
                </p>    

                <br /><br />         
               
                <Button color="warning" size="lg" type="button" target="_blank" href="https://www.eventcreate.com/e/renew-2025" rel="noreferrer" >
                  Register Now
                </Button> 

                <br /><br />
                 
                <h2 className="title">Life in the Spirit - Greta Gaut</h2>

                <p className="p-larger description ">
                  <strong>31st May 2025 | 9:15am - 12:30pm</strong>
                </p>
                <p className="p-larger description">
                  Doors open 8:45am
                </p>
                <p className="p-larger description">
                  Metford Community Baptist Church<br/> 
                  <a className="href-white" href="https://www.google.com/maps/place/83-85+Chelmsford+Dr,+Metford+NSW+2323/@-32.7665239,151.6066012,17z/data=!3m1!4b1!4m5!3m4!1s0x6b73414ce6ead803:0x9a597b828d8472de!8m2!3d-32.7665239!4d151.6087899" target="_blank" rel="noreferrer">83-85 Chelmsford Drive, Metford NSW</a><br/>
                </p>

                <br /><br />

                <h2 className="title">Registrations</h2>

                <br />

                <p className="p-larger description">
                  $20 Early Bird - For Concession/Students (ends 30th April)
                </p>
                
                <p className="p-larger description">
                  $25 Early Bird (ends 30th April)
                </p>

                <p className="p-larger description">
                  $35 Regular 
                </p>

                <br />
                
                <p className="p-larger description">
                  Registrations close 16th May. <br /> 
                  A beautiful morning tea is included. 
                </p>

                <br />
                <br />

                <Button color="warning" size="lg" type="button" target="_blank" href="https://www.eventcreate.com/e/renew-2025" rel="noreferrer" >
                  Register Now
                </Button>
                
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default CurrentConference;
